import React, { useEffect, useState } from "react";
import { Table, Pagination, Icon, Message, Header } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../redux/actions/user";
import Container from "../../components/Container/Container";

const DisplayUsersScreen = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.listUsers); 
 
  const [currentItems, setCurrentItems] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit] = useState(10); // Number of users per page
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
 
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (users?.length) {
      const total = Math.ceil(users.length / pageLimit);
      setTotalPages(total);
      setCurrentItems(users.slice(0, pageLimit));
    }
  }, [users, pageLimit]);

  const onPageChanged = (e, data) => {
    e.preventDefault();
    const { activePage } = data;
    const offset = (activePage - 1) * pageLimit;
    const current = users.slice(offset, offset + pageLimit);
    setActivePage(activePage);
    setCurrentItems(current);
  };

  return (
    <Container title="Users List">
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <Header as="h2" textAlign="center">
          Users Management
        </Header>

        {users.length === 0 ? (
          <Message info>
            <Message.Header>No users found.</Message.Header>
          </Message>
        ) : (
          <>
            <Table compact celled unstackable size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell>Balance</Table.HeaderCell>
                  <Table.HeaderCell>Bonus</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {currentItems.map((user, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{user.name}</Table.Cell>
                    <Table.Cell>{user.phone}</Table.Cell>
                    <Table.Cell>{user.profile?.balance}</Table.Cell>
                    <Table.Cell>{user.profile?.bonus}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Pagination
                activePage={activePage}
                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                firstItem={null}
                lastItem={null}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={totalPages}
                onPageChange={onPageChanged}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default DisplayUsersScreen;
