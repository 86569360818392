import Api from "../../api";
import Cookie from "react-cookies";
// Action Types


import { apiStart, apiEnd, apiError } from "./api";
import {
  FETCH_ALL_WINNERS_SUCCESS,
  FETCH_USER_WINNERS_SUCCESS,
  FETCH_WINNERS_FAILURE,
} from "./types";

import { getCurrentLanguage } from "../../constants/Utils";


function requestFail(errorCode, language) {
  return { type: FETCH_WINNERS_FAILURE, payload: { data: errorCode, language } };
}



export function addWinner(amount, type, onSuccess) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId"); // Load userId from cookies
    const language = getCurrentLanguage(); // Assuming this function exists for locale

    dispatch(apiStart());

    const formData = new URLSearchParams();
    formData.append("amount", amount);
    formData.append("type", type);

    Api.post(`/user/${userId}/winner`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          //onSuccess(response.data.result); // Pass the saved winner DTO to the callback
          dispatch(apiEnd());
        } else {
         // onSuccess(response.data.message); // Pass error message to the callback
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

// Fetch all winners
export const fetchAllWinners = () => async (dispatch) => {
  const userId = Cookie.load("userId");
  dispatch(apiStart());
  try {
    const response = await Api.get(`/user/${userId}/winners`);
    if (response.data.success) {
      dispatch({
        type: FETCH_ALL_WINNERS_SUCCESS,
        payload: {
          winners: response.data.result.winners || [],
          totalAmount: response.data.result.totalAmount || 0,
        },
      });
    } else {
      dispatch({
        type: FETCH_WINNERS_FAILURE,
        payload: { message: response.data.message },
      });
    }
  } catch (error) {
    console.error("Error fetching all winners:", error);
    dispatch({ type: FETCH_WINNERS_FAILURE, payload: { message: error.message } });
  } finally {
    dispatch(apiEnd());
  }
};

// Fetch user-specific winners
export const fetchUserWinners = (userId) => async (dispatch) => {
  dispatch(apiStart());
  try {
    const response = await Api.get(`/user/${userId}/winner`);
    if (response.data.success) {
      dispatch({
        type: FETCH_USER_WINNERS_SUCCESS,
        payload: {
          winners: response.data.result.winners || [],
          totalAmount: response.data.result.totalAmount || 0,
        },
      });
    } else {
      dispatch({
        type: FETCH_WINNERS_FAILURE,
        payload: { message: response.data.message },
      });
    }
  } catch (error) {
    console.error(`Error fetching winners for user ${userId}:`, error);
    dispatch({ type: FETCH_WINNERS_FAILURE, payload: { message: error.message } });
  } finally {
    dispatch(apiEnd());
  }
};

