import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Button, Form, Card, Message, Header } from "semantic-ui-react";
import { getUserByPhone, addCreditToUser } from "../../redux/actions/user";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
const UserCreditScreen = () => {
  const [phone, setPhone] = useState(""); // Phone number input
  const [searchedUser, setSearchedUser] = useState(null); // Searched user's info
  const [creditAmount, setCreditAmount] = useState(""); // Credit amount input
  const [error, setError] = useState(null); // Error message
  const [successMessage, setSuccessMessage] = useState(null); // Success message

  const dispatch = useDispatch();

  const handleSearchUser = async () => {
    if (!phone.trim()) {
      setError("Please enter a valid phone number.");
      return;
    }

    try {
      const userInfo = await dispatch(getUserByPhone(phone)); // Fetch searched user
      setSearchedUser(userInfo); // Set searched user's info
      setError(null); // Clear errors
      setSuccessMessage(null); // Clear success messages
    } catch (err) {
      setSearchedUser(null);
      setError("User not found. Please try again.");
    }
  };

  const handleAddCredit = async () => {
    if (!creditAmount || creditAmount <= 0) {
      setError("Please enter a valid credit amount.");
      return;
    }

    try {
      const updateUser = await dispatch(
        addCreditToUser(searchedUser.id, creditAmount)
      );

      setSuccessMessage(
        `Successfully added ${creditAmount} to ${searchedUser.name}'s bonus.`
      );

      setSearchedUser(updateUser);
      setCreditAmount(""); // Reset credit amount input
      setError(null); // Clear errors
    } catch (err) {
      setError("Failed to add credit. Please try again.");
    }
  };

  return (
    <Container title={getLabel("UserCredit")}>
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <Header as="h2" textAlign="center">
          User Credit Management
        </Header>

        {/* Search User Form */}
        <Form>
          <Form.Field>
            <label>Search by Phone Number</label>
            <Input
              placeholder="Enter phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              action={{
                icon: "search",
                onClick: handleSearchUser,
              }}
            />
          </Form.Field>
        </Form>

        {/* Error and Success Messages */}
        {error && <Message negative>{error}</Message>}
        {successMessage && <Message positive>{successMessage}</Message>}

        {/* Searched User Info Card */}
        {searchedUser && (
          <Card fluid>
            <Card.Content>
              <Card.Header>{searchedUser?.name}</Card.Header>
              <Card.Meta>{searchedUser?.phone}</Card.Meta>
              <Card.Description>
                Current Balance:{" "}
                <strong>{searchedUser?.profile?.balance}</strong>
              </Card.Description>
              <Card.Description>
                Current Bonus: <strong>{searchedUser?.profile?.bonus}</strong>
              </Card.Description>
            </Card.Content>
          </Card>
        )}

        {/* Add Credit Form */}
        {searchedUser && (
          <Form>
            <Form.Field>
              <label>Add Credit</label>
              <Input
                placeholder="Enter credit amount"
                type="number"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
              />
            </Form.Field>
            <Button primary onClick={handleAddCredit}>
              Add Credit
            </Button>
          </Form>
        )}
      </div>
    </Container>
  );
};

export default UserCreditScreen;
