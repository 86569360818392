import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import styles from "./Menu.module.css";
import { logout } from "../../redux/actions/user";
import RulesModal from "../Rules/RulesModal";
import { useHistory } from "react-router-dom";
import { getLabel } from "../../constants/Strings";

const Menu = ({user}) => {
  //console.log("user Menu : ",user);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMenu = (option) => {
    setIsOpen(false);
    if (option === "Logout") {
      handleLogout();
    } else if (option === "Rules") {
      history.push("/rules-screen");
    } else if (option === "Settings") {
      history.push("/settings");
    } else if (option === "Operation") {
      history.push("/operation");
    } else if (option === "Profile") {
      history.push("/home-screen");
    } else if (option === "Transaction") {
      history.push("/transaction");
    } else if (option === "Notification") {
      history.push("/notification");
    } else if (option === "Dashboard") {
      history.push("/dashboard");
    } else if (option === "Validation") {
      history.push("/validation");
    }else if (option === "Winner") {
      history.push("/winner");
    }else if (option === "UserCredit") {
      history.push("/user-credit");
    }else if (option === "UserList") {
      history.push("/user-list");
    }

    
  };

  // Close the modal when clicking outside
  const handleClickOutside = (event) => {
    if (event.target.classList.contains(styles.modalBackdrop)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className={styles.menuIcon} onClick={() => setIsOpen(true)}>
        <Icon name="bars" size="large" />
      </div>

      {isOpen && (
        <div className={styles.modalBackdrop} onClick={handleClickOutside}>
          <div className={styles.menu} onClick={(e) => e.stopPropagation()}>
            <div
              className={styles.menuItem}
              onClick={() => toggleMenu("Profile")}
            >
              <span className={styles.menuItemText}>{getLabel("Profile")}</span>
            </div>
            <div
              className={styles.menuItem}
              onClick={() => toggleMenu("Operation")}
            >
              <span className={styles.menuItemText}>
                {getLabel("Operation")}
              </span>
            </div>

            <div
              className={styles.menuItem}
              onClick={() => toggleMenu("Settings")}
            >
              <span className={styles.menuItemText}>
                {getLabel("Settings")}
              </span>
            </div>
            <div
              className={styles.menuItem}
              onClick={() => toggleMenu("Transaction")}
            >
              <span className={styles.menuItemText}>
                {getLabel("Transaction")}
              </span>
            </div>

            <div
              className={styles.menuItem}
              onClick={() => toggleMenu("Rules")}
            >
              <span className={styles.menuItemText}>{getLabel("Rules")}</span>
            </div>
           
            {user && user.phone === "50939063636" && (
              <>
                <div
                  className={styles.menuItem}
                  onClick={() => toggleMenu("Notification")}
                >
                  <span className={styles.menuItemText}>
                    {getLabel("Notification")}
                  </span>
                </div>

                <div
                  className={styles.menuItem}
                  onClick={() => toggleMenu("Dashboard")}
                >
                  <span className={styles.menuItemText}>
                    {getLabel("Dashboard")}
                  </span>
                </div>

                <div
                  className={styles.menuItem}
                  onClick={() => toggleMenu("Validation")}
                >
                  <span className={styles.menuItemText}>
                    {getLabel("ValidationTransaction")}
                  </span>
                </div>


                <div
                  className={styles.menuItem}
                  onClick={() => toggleMenu("Winner")}
                >
                  <span className={styles.menuItemText}>
                    {getLabel("Winner")}
                  </span>
                </div>


                <div
                  className={styles.menuItem}
                  onClick={() => toggleMenu("UserCredit")}
                >
                  <span className={styles.menuItemText}>
                    {getLabel("UserCredit")}
                  </span>
                </div>

                <div
                  className={styles.menuItem}
                  onClick={() => toggleMenu("UserList")}
                >
                  <span className={styles.menuItemText}>
                    {getLabel("UserList")}
                  </span>
                </div>

                
                
              </>
            )}

            <div
              className={styles.menuItem}
              onClick={() => toggleMenu("Logout")}
            >
              <span className={styles.menuItemText}>{getLabel("Logout")}</span>
            </div>
          </div>
        </div>
      )}
      <RulesModal
        isOpen={isRulesModalOpen}
        onClose={() => setIsRulesModalOpen(false)}
      />
    </>
  );
};

export default Menu;
