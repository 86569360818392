import { combineReducers } from "redux";

//Reducers
import loading from "./loading";
import user from "./user";
import navigation from "./navigation";
import share from "./share";
import history from "./history";
import settings from "./settings";
import transactions from "./transactions";
import error from "./error";
import success from "./success";
import notification from "./notifications";
import winner from "./winner";

export default combineReducers({
	loading,
	user,
	navigation,
	share,
	history,
	settings,
	transactions,
	error,
	success,
	notification,
	winner
});
