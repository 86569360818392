import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Table,
  Pagination,
  Icon,
  Card,
  Statistic,
  Grid,
  Header,
  Dropdown,
} from "semantic-ui-react";
import Container from "../../components/Container/Container";
import { getLabel } from "../../constants/Strings";
import { dateFormat, currencyFormat } from "../../constants/Utils";
import { fetchAllWinners, fetchUserWinners } from "../../redux/actions/winner";
import styles from "./WinnerScreen.module.css";

class WinnerScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allItems: [], // All winners data
      currentItems: [], // Winners for current page
      totalPages: 0,
      activePage: 1,
      pageLimit: 10, // Winners per page
      selectedUserOption: "all", // 'all' or 'specific'
      userId: "", // Specific User ID for filtering
    };
  }

  componentDidMount = async () => {
    const { selectedUserOption, userId } = this.state;
    await this.fetchWinners(selectedUserOption, userId);
  };

  fetchWinners = async (selectedUserOption, userId) => {
    if (selectedUserOption === "all") {
      await this.props.dispatch(fetchAllWinners());
    } else if (selectedUserOption === "specific" && userId) {
      await this.props.dispatch(fetchUserWinners(userId));
    }

    const allItems = this.props.winner.winners || [];
    const totalPages = Math.ceil(allItems.length / this.state.pageLimit);
    const currentItems = allItems.slice(0, this.state.pageLimit);
    this.setState({ allItems, currentItems, totalPages });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevState.selectedUserOption !== this.state.selectedUserOption ||
      prevState.userId !== this.state.userId
    ) {
      await this.fetchWinners(this.state.selectedUserOption, this.state.userId);
    }
  };

  handlePageChange = (e, data) => {
    const { activePage } = data;
    const offset = (activePage - 1) * this.state.pageLimit;
    const currentItems = this.state.allItems.slice(
      offset,
      offset + this.state.pageLimit
    );
    this.setState({ activePage, currentItems });
  };

  handleUserOptionChange = (e, { value }) => {
    this.setState({
      selectedUserOption: value,
      userId: value === "all" ? "" : this.state.userId,
    });
  };

  handleUserIdChange = (e) => {
    this.setState({ userId: e.target.value });
  };

  renderTableRows = (data) => {
    if (!data || data.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan="4" textAlign="center">
            {getLabel("No winners found.")}
          </Table.Cell>
        </Table.Row>
      );
    }
  
    return data.map((winner, index) => (
      <Table.Row key={index}>
        <Table.Cell>{winner?.type}</Table.Cell>
        <Table.Cell>{winner?.person?.name || "-"}</Table.Cell>
        <Table.Cell>{currencyFormat(winner.amount)}</Table.Cell>
        <Table.Cell>{dateFormat(winner.createdDate)}</Table.Cell>
      </Table.Row>
    ));
  };
  

  render() {
    const { currentItems, activePage, totalPages, selectedUserOption, userId } =
      this.state;
    const totalAmount = this.props.winner.totalAmount || 0;

    return (
      <Container title={getLabel("Winner")}>
        <div className={styles.tableContainer}>
          <Header as="h2" textAlign="center" className={styles.header}>
            {getLabel("Winner")}
          </Header>

          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Dropdown
                  placeholder="Select Option"
                  fluid
                  selection
                  options={[
                    { key: "all", text: "All Users", value: "all" },
                    {
                      key: "specific",
                      text: "Specific User",
                      value: "specific",
                    },
                  ]}
                  onChange={this.handleUserOptionChange}
                  value={selectedUserOption}
                />
              </Grid.Column>
              {selectedUserOption === "specific" && (
                <Grid.Column width={10}>
                  <input
                    type="text"
                    placeholder="Enter User ID"
                    value={userId}
                    onChange={this.handleUserIdChange}
                    className={styles.userInput}
                  />
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Table compact celled unstackable size="small" columns={16}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell>
                        {getLabel("FirstName")}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{getLabel("Amount")}</Table.HeaderCell>
                      <Table.HeaderCell>{getLabel("Date")}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {currentItems.length > 0 ? (
                      this.renderTableRows(currentItems)
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan="4" textAlign="center">
                          {getLabel("No winners found.")}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Pagination
                  activePage={activePage}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  firstItem={null}
                  lastItem={null}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                  }}
                  totalPages={totalPages}
                  onPageChange={this.handlePageChange}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Statistic>
                      <Statistic.Label>
                        {getLabel("Total Winning Amount")}
                      </Statistic.Label>
                      <Statistic.Value>
                        {currencyFormat(totalAmount)}
                      </Statistic.Value>
                    </Statistic>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

function mapStateToProps({ winner }) {
  return { winner };
}

export default connect(mapStateToProps)(WinnerScreen);
