import {
    FETCH_ALL_WINNERS_SUCCESS,
    FETCH_USER_WINNERS_SUCCESS,
    FETCH_WINNERS_FAILURE,
  } from "../actions/types";
  
// winnerReducer.js
const initialState = {
    winners: [],
    totalAmount: 0,
    createdDate:"",
    error: null,
  };
  
  const winner = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_WINNERS_SUCCESS:
      case FETCH_USER_WINNERS_SUCCESS:
        return {
          ...state,
          winners: action.payload.winners,
          totalAmount: action.payload.totalAmount,
          createdDate: action.payload.createdDate,
          error: null,
        };
      case FETCH_WINNERS_FAILURE:
        return {
          ...state,
          winners: [],
          totalAmount: 0,
          createdDate:"",
          error: action.payload.message,
        };
      default:
        return state;
    }
  };
  
  export default winner;
  
  
  