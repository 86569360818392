import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import {
  LOGIN,
  UPDATE_USER_CREDIT_PAYLOAD,
  API_FAILURE,
  REGISTER_CLIENT,
  LOGOUT,
  LOGGED_USER,
  EDIT_USER,
  VERIFY_PIN,
  VERIFY_PIN_FAILURE,
  RESET_PASSWORD,
  DISMISS_MODAL,
  UPDATE_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  GET_TRANSACTIONS,
  CAN_WIN,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  ADD_CREDIT_SUCCESS,
  ADD_CREDIT_FAILURE,
  SET_USERS
} from "./types";
import Cookie from "react-cookies";
import { getCurrentLanguage } from "../../constants/Utils";
import { navigate } from "./navigation";
import { onSuccess } from "./success";

function requestUserSuccess(type, user) {
  return { type, payload: { user } };
}

function requestTransactionSuccess(type, data) {
  return { type, payload: { data } };
}

// function setSessionId(sessionId) {
//   Cookie.save('sessionId', sessionId, {
//     path: '/',     // Make it accessible globally
//     maxAge: 86400, // Set the cookie to expire after 1 day
//   });
// }npm start

function requestSuccess(type, data) {
  return { type, payload: { data } };
}

function requestFail(errorCode, language) {
  return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getTransactions() {
  const language = getCurrentLanguage();
  return async (dispatch) => {
    const userId = await Cookie.load("userId");

    if (userId !== undefined) {
      try {
        const response = await Api.get(`user/${userId}/transactions`);

        if (response.data && response.data.success) {
          const transactions = response.data.result;

          dispatch(requestTransactionSuccess(GET_TRANSACTIONS, transactions));
          //window.location.href = 'withdrawal';
        } else {
          dispatch(requestFail(response.data.message, language));
        }
      } catch (error) {
        dispatch(apiError(error, language));
      } finally {
        dispatch(apiEnd());
      }
    } else {
      const noUser = {
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        balance: 0,
        bonus: 0,
        userType: {},
        profile: {},
      };
      dispatch(requestUserSuccess(LOGGED_USER, noUser));
    }
  };
}

export function getPendingTransactions() {
  const language = getCurrentLanguage();
  return async (dispatch) => {
    const userId = await Cookie.load("userId");

    if (userId !== undefined) {
      try {
        const response = await Api.get(`user/${userId}/ptransactions`);

        if (response.data && response.data.success) {
          const transactions = response.data.result;

          dispatch(requestTransactionSuccess(GET_TRANSACTIONS, transactions));
          //window.location.href = 'withdrawal';
        } else {
          dispatch(requestFail(response.data.message, language));
        }
      } catch (error) {
        dispatch(apiError(error, language));
      } finally {
        dispatch(apiEnd());
      }
    } else {
      const noUser = {
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        balance: 0,
        bonus: 0,
        userType: {},
        profile: {},
      };
      dispatch(requestUserSuccess(UPDATE_USER_CREDIT_PAYLOAD, noUser));
    }
  };
}

export function rehydrate() {
  const language = getCurrentLanguage();
  return async (dispatch) => {
    // Check for required cookies
    const authorization = await Cookie.load("authorization");
    const apiKey = await Cookie.load("apiKey");
    const sessionId = await Cookie.load("sessionId");

    // If any of the required cookies are missing, dispatch noUser response and return early
    if (!authorization || !apiKey || !sessionId) {
      const noUser = {
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        balance: 0,
        bonus: 0,
        userType: {},
        profile: {},
      };
      dispatch(requestUserSuccess(LOGGED_USER, noUser));
      return;
    }

    const userId = await Cookie.load("userId");

    if (userId !== undefined) {
      try {
        const response = await Api.get(`/user/${userId}`);

        if (response.data && response.data.success) {
          const user = response.data.result;
          dispatch(requestUserSuccess(LOGGED_USER, user));
          // window.location.href = 'withdrawal';
        } else {
          dispatch(requestFail(response.data.message, language));
        }
      } catch (error) {
        dispatch(apiError(error, language));
      } finally {
        dispatch(apiEnd());
      }
    } else {
      const noUser = {
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        balance: 0,
        bonus: 0,
        userType: {},
        profile: {},
      };
      dispatch(requestUserSuccess(LOGGED_USER, noUser));
    }
  };
}

export function login(phone, password) {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());

    // Prepare URL-encoded payload
    const formUrlEncodedData = toFormUrlEncoded({ phone, password });

    try {
      const response = await Api.post("/login", formUrlEncodedData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Ensure this matches the encoding type
        },
        withCredentials: true,
      });

      if (response.data && response.data.success) {
        const { user } = response.data.result;
        Cookie.save("userId", user.id);
        dispatch(requestUserSuccess(LOGIN, user));
      } else {
        dispatch(requestFail(response.data.message, language));
      }
    } catch (error) {
      dispatch(apiError(error, language));
    } finally {
      dispatch(apiEnd());
    }
  };
}

// Helper function to URL-encode the data
const toFormUrlEncoded = (obj) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");

export function logout() {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    Api.post(`/user/${userId}/logout`, null, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
    }).then(async (response) => {
      if (response.data.success) {
        await Cookie.remove("userId");
        dispatch(requestUserSuccess(LOGOUT, null));
      }
    });
  };
}

export function register(lastName, name, phone, password, pin) {
  //console.log("HI");
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ lastName, name, phone, password, pin });
    Api.post("/register", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          const { user } = response.data.result;
          Cookie.save();
          Api.defaults.headers.common["userId"] = user.id;
          dispatch(requestUserSuccess(REGISTER_CLIENT, user));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function registerSeller(
  name,
  phone,
  city,
  zone,
  address,
  password,
  pin
) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/createVendeur", {
      nom: name,
      tel: phone,
      ville: city,
      zone,
      address,
      motDePasse: password,
      pin,
    })
      .then((response) => {
        if (response.data.success) {
          const { user, token } = response.data.data;
          const authToken = { user: user, token: token };
          Cookie.save("authToken", authToken, { path: "/", maxAge: 3600 });
          Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          Api.defaults.headers.common["userId"] = user._id;
          dispatch(requestUserSuccess(REGISTER_CLIENT, user, token));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function fetchUserInfo() {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    const userId = await Cookie.load("userId");
    dispatch(apiStart());
    Api.get(`/user/${userId}`)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.result;

          dispatch(requestSuccess(UPDATE_USER_CREDIT_PAYLOAD, data));

          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function fetchAllUsers() {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    const userId = await Cookie.load("userId");
    dispatch(apiStart());
    try{
      const response = await Api.get(`/user/${userId}/all`);
 
        if (response.data.success) {
          const data = response.data.result;

          dispatch(requestSuccess(SET_USERS, data));

          dispatch(apiEnd());
          return response;
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      
      }catch(error) {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      }
  };
}



export function updateUserInfo() {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.put(`/user/${userId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(UPDATE_USER, response.data.result));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function acceptPendingTransaction(transactionId) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    Api.get(`user/${userId}/valide/transaction/${transactionId}/accept`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(response.data.result));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export const getTotalExistUsers = () => async (dispatch) => {
  try {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const response = await Api.get(`user/${userId}/total`);

    if (response.data.success) {
      dispatch(requestSuccess(response.data.result));
      dispatch(apiEnd());
      return response; // Return the API response explicitly
    } else {
      dispatch(apiEnd());
      dispatch(requestFail(response.data.message, language));
      return response; // Return the response even if not successful
    }
  } catch (error) {
    dispatch(apiError(error, getCurrentLanguage()));
    dispatch(apiEnd());
    return {
      data: { success: false, result: null, message: "Error fetching data" },
    }; // Return a default object
  }
};

export const getTotalExistUsersMonth = (month) => async (dispatch) => {
  try {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const formData = toFormUrlEncoded({ dateNumber: month + "" });

    const response = await Api.post(`user/${userId}/month`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
    });

    if (response.data.success) {
      dispatch(requestSuccess(response.data.result));
      dispatch(apiEnd());
      return response; // Return the API response explicitly
    } else {
      dispatch(apiEnd());
      dispatch(requestFail(response.data.message, language));
      return response; // Return the response even if not successful
    }
  } catch (error) {
    dispatch(apiError(error, getCurrentLanguage()));
    dispatch(apiEnd());
    return {
      data: { success: false, result: null, message: "Error fetching data" },
    }; // Return a default object
  }
};

export const getTotalExistUsersWeek = () => async (dispatch) => {
  try {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const response = await Api.get(`user/${userId}/week`);

    if (response.data.success) {
      dispatch(requestSuccess(response.data.result));
      dispatch(apiEnd());
      return response; // Return the API response explicitly
    } else {
      dispatch(apiEnd());
      dispatch(requestFail(response.data.message, language));
      return response; // Return the response even if not successful
    }
  } catch (error) {
    dispatch(apiError(error, getCurrentLanguage()));
    dispatch(apiEnd());
    return {
      data: { success: false, result: null, message: "Error fetching data" },
    }; // Return a default object
  }
};

export const getTotalExistUsersToday = () => async (dispatch) => {
  try {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const response = await Api.get(`user/${userId}/today`);

    if (response.data.success) {
      dispatch(requestSuccess(response.data.result));
      dispatch(apiEnd());
      return response; // Return the API response explicitly
    } else {
      dispatch(apiEnd());
      dispatch(requestFail(response.data.message, language));
      return response; // Return the response even if not successful
    }
  } catch (error) {
    dispatch(apiError(error, getCurrentLanguage()));
    dispatch(apiEnd());
    return {
      data: { success: false, result: null, message: "Error fetching data" },
    }; // Return a default object
  }
};

export function rejectPendingTransaction(transactionId) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();

    dispatch(apiStart());
    Api.get(`user/${userId}/valide/transaction/${transactionId}/reject`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(response.data.result));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function rechargeMoncash(amount, phone, windowRef) {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    const userId = await Cookie.load("userId");
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone: phone, amount: amount * 1 });

    Api.post(`/user/${userId}/pay`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(apiEnd());
          windowRef.location = response.data.result;
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function transferFundMoncash(amount, phone, onSuccess) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();

    dispatch(apiStart());
    const formData = toFormUrlEncoded({
      phone: phone,
      amount: amount * 1,
      type: "Balance",
    });

    Api.post(`/user/${userId}/transfer`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        //debugger;
        if (response.data.success) {
          onSuccess("true");
          dispatch(apiEnd());
        } else {
          onSuccess(response.data.message);
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function updateCredit(amount, type, operation, via, isNotification) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();

    // Ensure the amount is a positive number
    const positiveAmount = Math.abs(amount);
    const formData = toFormUrlEncoded({
      type,
      amount: positiveAmount,
      operation,
      via,
      isNotification,
    });

    dispatch(apiStart());

    Api.put(`/user/${userId}/credit`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true, // Ensure that cookies are sent with the request
    })
      .then((response) => {
        if (response.data.success) {
          // Update the user's credit information in the local state
          dispatch(
            requestSuccess(UPDATE_USER_CREDIT_PAYLOAD, response.data.result)
          );

          // Fetch the latest user data to ensure consistency
          //dispatch(fetchUserInfo());
        } else {
          // Handle failure and display any relevant error messages
          dispatch(requestFail(response.data.message, language));
        }
        dispatch(apiEnd());
      })
      .catch((error) => {
        // End the API call and handle errors
        dispatch(apiEnd());
        dispatch(apiError(error, language));
      });
  };
}

export function userCanWinNow() {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    Api.get(`/user/${userId}/canWin`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((response) => {
      if (response.data.success) {
        dispatch(requestSuccess(CAN_WIN, response.data.result));
        dispatch(apiEnd());
      } else {
        dispatch(apiEnd());
        dispatch(requestFail(response.data.message, language));
      }
    });
  };
}

export function editUser(user, closeModal) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.patch(`/user/${userId}`, {
      user,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(EDIT_USER, response.data.data));
          dispatch(apiEnd());
          closeModal();
          dispatch(onSuccess("Edit_Account", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function verifyUser(phone, isSuccess) {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone: phone });
    Api.post("/verify", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })

      .then((response) => {
        if (response.data.success) {
          if (response.data.result) {
            dispatch(requestSuccess(VERIFY_USER_SUCCESS, phone));
          } else {
            dispatch(requestSuccess(VERIFY_USER_FAILURE, null));
          }
        } else {
          dispatch(requestSuccess(VERIFY_USER_FAILURE, null));
        }
        dispatch(apiEnd());
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function verifyPin(phone, pin) {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone: phone, pin: pin });
    Api.post("/verifyPin", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          if (response.data.result) {
            dispatch(requestSuccess(VERIFY_PIN, pin));
            dispatch(apiEnd());
          } else {
            dispatch(requestSuccess(VERIFY_PIN_FAILURE, null));
            dispatch(apiEnd());
          }
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function resetPassword(phone, pin, password) {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const formData = toFormUrlEncoded({
      phone: phone,
      pin: pin,
      password: password,
    });
    Api.put("/updatePassword", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(RESET_PASSWORD, null));
          dispatch(onSuccess("Reset_Password", language));
          dispatch(navigate("/"));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function dismissModals() {
  return (dispatch) => {
    dispatch(requestSuccess(DISMISS_MODAL, null));
  };
}

export function getUserByPhone(phone) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    try {
      const response = await Api.get(`/user/${userId}/search`, {
        params: { phone },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.success) {
        const user = response.data.result;
        dispatch(requestSuccess(SEARCH_USER_SUCCESS, user));
        dispatch(apiEnd());
        return user; // Return the user data
      } else {
        dispatch(requestFail(SEARCH_USER_FAILURE, response.data.message));
        dispatch(apiEnd());
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(apiError(error, language));
      dispatch(apiEnd());
      throw error; // Ensure the error is propagated
    }
  };
}

export function addCreditToUser(targetUserId, amount2) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const amount = Math.abs(amount2 * 1);
    const formData = toFormUrlEncoded({ amount, targetUserId });
    try {
      const response = await Api.put(`/user/${userId}/addcredit`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.success) {
        const user = response.data.result;
        dispatch(requestSuccess(ADD_CREDIT_SUCCESS, amount));
        dispatch(apiEnd());
        return user;
      } else {
        dispatch(requestFail(ADD_CREDIT_FAILURE, response.data.message));
        dispatch(apiEnd());
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(apiError(error, language));
      dispatch(apiEnd());
      throw error;
    }
  };
}
